.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 0px 0px 0 10px; /* Elimina el padding en el lado superior y derecho */
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 133px;
  border-radius: 4px; /* Modificado */
  color: white;
  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .title {
      font-weight: bold;
      font-size: 11px;
      color: rgb(255, 255, 255);
      margin-left: 10px; /* Margen izquierdo */
      margin-top: 20px;  /* Margen superior */
      line-height: 13px;
    }

    .counter {
      font-size: 28px;
      // font-weight: 300;
      margin-left: 10px; /* Margen izquierdo */
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid gray;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }

    .icon {
      font-size: 18px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
      color: white; /* Icono de color blanco */
      align-self: center; /* No es necesario si ya estás usando align-items y justify-content en .right */
    }
  }
  .right {
    flex: 0.3; /* Ocupa el 20% del ancho del widget */
    background: rgba(255, 255, 255, 0.25); /* Color de fondo deseado */
    margin: 0; /* Elimina márgenes */
    border: none; /* Elimina bordes */
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
  }
}

/* Estilos específicos para cada tipo de widget */
.widget-type1 {
  background: rgba(70, 120, 195, 1);
}

.widget-type2 {
  background: rgba(26, 41, 66, 1);
}

.widget-type3 {
  background: rgba(122, 164, 192, 1);
}

// Estilos para el componente BarChartWidget
.bar-chart-widget {
  position: relative;
  z-index: 0; // Asegurarte de que el gráfico se muestre detrás de otros elementos
}