.home {
  display: flex;

  .homeContainer {
    flex: 6;
    display: flex;
    flex-direction: column;
    background-color: rgba(18, 120, 189, 0.05);
    
    &.collapsed {
      flex: 14;
      display: flex;
    }
    .user-greeting {
      color: #1278bd;
      margin-left: 20px;
      margin-top: 20px;
    }

    .box-container {
      display: flex;
      margin-bottom: 20px;

      .service-info-box,
      .update-box {
        min-width: 500px;
        min-height: 300px;
        margin: 0 10px;
        background-color: white;
        padding: 10px;
      }

      .update-box{
        min-height: 550px;
      }
    }

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      padding: 20px;
      margin: 20px;

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }

    /* Resto del código SCSS */

    .recent-documents {
      flex-direction: column;
      align-items: flex-start;

      ul {
        list-style: none;
        padding: 0;
        border-top: 0.25px solid #1278bd; /* Barra separadora arriba de cada elemento */
      }

      .document-item {
        display: flex;
        align-items: center; /* Alinea el punto azul con el resto de los elementos */
        padding: 10px 0; /* Espacio adicional entre elementos */

        &::before {
          content: "";
          width: 15px; /* Aumenta el tamaño del punto azul */
          height: 15px; /* Aumenta el tamaño del punto azul */
          background-color: #1278bd; /* Color azul */
          border-radius: 50%;
          margin-right: 15px; /* Aumenta el espacio entre el punto azul y el contenido */
        }

        .info {
          flex-grow: 1; /* Ocupa todo el espacio disponible */
          display: flex;
          justify-content: space-between; /* Coloca la fecha en el margen derecho */
          align-items: center;
          margin-left: 10px; /* Espacio entre el punto azul y el contenido */
          width: 100%;

          .name-text {
            display: flex;
            flex-direction: column; /* Nombre y apellidos debajo de Document Type */
            align-items: flex-start;

            span {
              font-weight: bold;
              margin-bottom: 5px; /* Espacio entre Document Type y nombre */
            }
          }

          p.date-text {
            margin-left: auto; /* Mueve la fecha al margen derecho */
            margin-right: 0; /* Asegura que no haya margen adicional a la derecha */
          }
        }
      }

      h2 {
        color: #1278bd; /* Color azul */
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 10px;
        line-height: 19.2px;
      }
    }
  }
}
