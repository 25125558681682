.update-box {
  max-height: 500px;
  overflow-y: auto;
  padding: 20px 40px;  /* Aumento del margen horizontal */
  margin-top: 20px;
  margin-left: 20px;
  box-sizing: border-box;

  .title {
    color: #1278bd; /* Color azul */
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 10px;
    font-weight: 600;
    line-height: 19.2px;
    position: relative; /* Para agregar la línea debajo del título */
    font-size: 24;
    &::after {
      /* Línea debajo del título */
      content: "";
      position: absolute;
      margin-top: 20px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #1278bd; /* Color azul */
    }
  }

  .update {
    border: none;  /* Removido el borde */
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .update-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .update-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .update-date {
    color: rgba(116, 120, 141, 0.50);
    font-family: "Be Vietnam";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
  }

  .update-text {
    color: #74788D;
    font-family: "Be Vietnam";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;

    ul {
      list-style-type: square;

      li::before {
        width: 10px;  /* Tamaño reducido del punto */
        height: 10px;
      }
    }
  }
}
