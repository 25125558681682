.new {
  width: 100%;
  display: flex;
  .newContainer {
    flex: 6;
    background-color: rgba(18, 120, 189, 0.05);
    &.collapsed {
      flex: 14;
    }
    .top {
      h1 {
        color: rgba(14, 119, 189, 1);
        font-size: 35px;
        line-height: 75px;
        font-weight: 600;
        padding: 10px;
        margin: 20px;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      margin: 20px;
      display: flex;
      background-color: #fff;

      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center; /* Centrar horizontalmente */
        justify-content: center; /* Centrar verticalmente */

        img {
          width: 200px; /* Aumentar el tamaño de la imagen */
          height: 200px; /* Aumentar el tamaño de la imagen */
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 35px;
        }
        .upload-label {
          background: rgba(230, 230, 230, 1);
          padding: 16px;
          width: 182px;
          text-align: center; /* Centrar texto horizontalmente */
          cursor: pointer;
        }
      }

      .right {
        flex: 1;

        form {
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-around;
          /* Estilo para los campos de entrada en pares */
          .inputPairs {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: space-between;
            margin-bottom: 20px; /* Añade margen inferior para separar de los siguientes campos */
            .dropdown,
            .cliente-select {
              width: 50%;
            }
          }

          /* Estilo específico para los campos de entrada en pares */
          .inputPairs .formInput {
            width: calc(
              50% - 15px
            ); /* Ocupar el 50% del ancho, dejando espacio entre ellos */
          }

          .formInput {
            label {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-left: 15px;
              .icon {
                cursor: pointer;
              }
            }

            .cliente-select {
              max-height: 50px;
              width: 200px;
              margin-left: 15px;
              border-color: rgba(198, 198, 200, 1);
            }
            /* Estilo para ServiceDropdown */
            .ServiceDropdown {
              width: 100%; /* Ocupa el 100% del ancho del contenedor */
            }

            input {
              /* ... (resto del código existente) */
              border: 1px solid rgba(198, 198, 200, 1); /* Mostrar todos los bordes */
              border-radius: 4px; /* Agregar un pequeño borde redondeado */
              height: 51px; /* Altura de 51px */
              background-color: rgba(251, 251, 251, 1); /* Color de fondo */
              margin: 15px;
              padding-left: 10px; /* Agregar margen a la derecha del placeholder */
            }

            /* Estilo específico para los inputs "nombre" y "apellidos" */
            #nombre,
            #apellidos {
              width: 90%;
            }

            /* Estilo específico para los inputs "dni" y "tipVS" */
            #dni,
            #tipVS {
              width: 80%; /* Ocupar la mitad del ancho, dejando un pequeño espacio entre ellos */
            }
          }
          /* Estilo para el contenedor del botón */
          .button-container {
            display: flex;
            justify-content: flex-end; /* Alinea el botón a la derecha */
            width: 100%; /* Ocupa todo el ancho del contenedor */
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background: rgba(51, 161, 134, 1);
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
            align-self: right;
            &:disabled {
              background-color: rgba(3, 92, 92, 0.322);
              cursor: not-allowed;
            }
          }
          .dropdown {
            background-color: #fff;
            .dropdown-input {
              height: 15px;
            }
          }

          /* Estilo para los inputs impares dentro del .dropdown */
          .dropdown .dropdown-input:nth-child(odd) {
            background-color: #ccc; /* Fondo gris para elementos impares */
          }

          /* Estilo para el fake-dropdown */
          .fake-dropdown {
            position: relative; /* Establece la posición relativa */
            z-index: 1; /* Establece un z-index menor que el del dropdown para que esté detrás */
            background-color: white;
            color: black;
            border: 1px solid #ccc;
            font-size: 14px;
            border-radius: 4px;
            width: 200px;
            height: 51px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center; /* Centra verticalmente el contenido */
            justify-content: space-between; /* Alinea los elementos en los extremos horizontalmente */
            padding: 0 10px;
          }

          /* Estilo para el texto del fake-dropdown */
          .dropdown-text {
            flex-grow: 1; /* El texto ocupa el espacio disponible */
            text-align: center; /* Centra el texto horizontalmente */
          }
          /* Estilo para el cuadro desplegable */
          .dropdown-content {
            position: absolute; /* Establece la posición absoluta */
            z-index: 2; /* Establece un z-index más alto para que esté por encima del contenido */
            background-color: #fff;
            border: 1px solid black; /* Agregar un borde sólido */
            max-height: 300px; /* ajusta la altura máxima según tus necesidades */
            overflow-y: auto;
          }
          .dropdown-checkbox {
            display: flex;
          }
          .fake-dropdown::after {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.delete-tienda {
  width: 50px !important;
  display: inline-block !important;
  margin-left: 5px !important;
  padding: 0 2px !important;
  color: white !important;
  background-color: red !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
.formInputTienda {
  padding: 0 10px;
}
